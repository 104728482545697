import { initializeApp } from 'firebase/app';
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyAOZqqGtSklZ4noR3lfujt_5oAXaaHRF6A",
    authDomain: "deep-way-333011.firebaseapp.com",
    projectId: "deep-way-333011",
    storageBucket: "deep-way-333011.appspot.com",
    messagingSenderId: "457097087480",
    appId: "1:457097087480:web:8ea8c9dfd544a3cc392e0d"
};

export const app = initializeApp(firebaseConfig);
