<template>

  <form @submit.prevent="handleSubmit" v-if="!formSubmit">
    <img src="../assets/logo.jpg"/>
    <br />
    <h2>Please fill in the form below.</h2>
    <label>Name</label>
    <input v-model="form.name" type="text" required>

    <label>Office</label>
    <input v-model="form.office" type="text" required>


    <label>Please select the IA Group equipment you have at the <span class="red">office</span>.</label>

    <div class="terms">
      <input type="checkbox" v-model="form.hasNuc">
      <label>NUC</label>

      <input type="checkbox" v-model="form.hasLaptop">
      <label>Laptop</label>

      <input type="checkbox" v-model="form.hasHeadsetAtWork">
      <label>Headset</label>

      <input type="checkbox" v-model="form.hasScreenAtWork">
      <label>Screen(s)</label>

      <input type="checkbox" v-model="form.hasWebcamAtWork">
      <label>Webcam</label>
    </div>

    <label>Please select the IA Group equipment you have at <span class="red">home</span>.</label>
    <div class="terms">
      <input type="checkbox" v-model="form.hasLaptop">
      <label>Laptop</label>
      <input type="checkbox" v-model="form.hasHeadsetAtHome">
      <label>Headset</label>
      <input type="checkbox" v-model="form.hasScreenAtHome">
      <label>Screen(s)</label>
      <input type="checkbox" v-model="form.hasWebcamAtHome">
      <label>Webcam</label>
      <input type="checkbox" v-model="form.hasDeskAtHome">
      <label>Desk</label>
      <input type="checkbox" v-model="form.hasChairAtHome">
      <label>Chair</label>
      <input type="checkbox" v-model="form.hasKeyboardMiceAtHome">
      <label>Keyboard/Mouse</label>

      <input type="checkbox" v-model="form.hasOtherAtHome">
      <label>Other</label>
    </div>

    <div v-if="form.hasNuc">
      <label>NUC number (starts with NUCIA)</label>
      <input type="text" placeholder="insert nucia number" v-model="form.nucia_number" required>
    </div>

    <div v-if="form.hasLaptop">
      <label>Laptop number (starts with IAUB)</label>
      <input type="text" placeholder="insert iaub number" v-model="form.iaub_number" required>
    </div>

    <div v-if="form.hasScreenAtWork">
      <label>How many screens do you have at <span class="red">work</span>?</label>
      <input type="number" placeholder="" v-model="form.screens_at_work" required>
      <label>Screen has buildin Webcam</label><br />
      <input type="radio" name="ScreenAtWorkIncludeWebcam" value="yes" v-model="form.screens_at_work_include_webcam"> Yes
      <input type="radio" name="ScreenAtWorkIncludeWebcam" value="no" v-model="form.screens_at_work_include_webcam"> No
    </div>

    <div v-if="form.hasScreenAtHome">
      <label>How many screens do you have at <span class="red">home</span>?</label>
      <input type="number" placeholder="" v-model="form.screens_at_home" required>
      <label>Screen has buildin Webcam</label><br />
      <input type="radio" name="ScreenAtHomeIncludeWebcam" value="yes" v-model="form.screens_at_home_include_webcam"> Yes
      <input type="radio" name="ScreenAtHomeIncludeWebcam" value="no" v-model="form.screens_at_home_include_webcam"> No
    </div>

    <div v-if="form.hasOtherAtHome">
      <label>What other equipment do you have at <span class="red">home</span>?</label>
      <input v-model="form.other_at_home" type="text" placeholder="" required>
    </div>

    <div v-if="form.hasKeyboardMiceAtHome">
      <label>Do you use the same Keyboard and Mouse set from the office at home?</label>
      <input type="radio" v-model="form.inputDevices" value="Same set for home and office">
      <label>I use the same set for both home and office</label> <br />
      <input type="radio" v-model="form.inputDevices" value="2 different sets from IA">
      <label>I use 2 different sets of IA for home and office</label><br />
      <input type="radio" v-model="form.inputDevices" value="Using own set at home">
      <label>I use my own set at home.</label>
      <div v-if="keyboardmiceError">
        <span class="error">{{ keyboardmiceError }}</span>
      </div>
    </div>
    <button>Submit my equipment</button>
  </form>
  <div v-if="formSubmit">
    Thank you for taking the time to complete this form.
  </div>
</template>

<script>
import { app } from "../../firebase";
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore/lite';

export default {
  data() {
    return {
      form: {
        name: '',
        office: '',
        nucia_number: '',
        iaub_number: '',
        other_at_home: '',
        screens_at_home: '',
        screens_at_work: '',
        screens_at_work_include_webcam: '',
        screens_at_home_include_webcam: '',
        hasNuc: false,
        hasLaptop: false,
        hasHeadsetAtWork: false,
        hasScreenAtWork: false,
        hasWebcamAtWork: false,
        hasHeadsetAtHome: false,
        hasScreenAtHome: false,
        hasWebcamAtHome: false,
        hasDeskAtHome: false,
        hasChairAtHome: false,
        hasOtherAtHome: false,
        hasKeyboardMiceAtHome: false,
        inputDevices: '',
      },
      keyboardmiceError: '',
      formSubmit: false,
    }
  },
  setup() {

  },
  methods:
      {
        handleSubmit() {
          const db = getFirestore();
          const colRef = collection(db, 'formresults');
          //validate keyboard mice input
          this.keyboardmiceError = this.inputDevices ? '' : 'Please select 1 option'

          addDoc(colRef, {
            name : this.form.name,
            office : this.form.office,
            nucia_number : this.form.nucia_number,
            iaub_number : this.form.iaub_number,
            other_at_home : this.form.other_at_home,
            screens_at_home : this.form.screens_at_home,
            screens_at_work : this.form.screens_at_work,
            screens_at_home_include_webcam : this.form.screens_at_home_include_webcam,
            screens_at_work_include_webcam : this.form.screens_at_work_include_webcam,
            hasNuc : this.form.hasNuc,
            hasLaptop : this.form.hasLaptop,
            hasHeadsetAtWork : this.form.hasHeadsetAtWork,
            hasScreenAtWork : this.form.hasScreenAtWork,
            hasWebcamAtWork : this.form.hasWebcamAtWork,
            hasHeadsetAtHome : this.form.hasHeadsetAtHome,
            hasScreenAtHome : this.form.hasScreenAtHome,
            hasWebcamAtHome : this.form.hasWebcamAtHome,
            hasDeskAtHome : this.form.hasDeskAtHome,
            hasChairAtHome : this.form.hasChairAtHome,
            hasOtherAtHome : this.form.hasOtherAtHome,
            hasKeyboardMiceAtHome : this.form.hasKeyboardMiceAtHome,
            inputDevices : this.form.inputDevices,
          }).then(() => {
            //reset form
            this.formSubmit = true;
          })

        }
      },
}
</script>

<style>
form {
  max-width: 420px;
  margin: 30px auto;
  background: white;
  text-align: left;
  padding: 40px;
  border-radius: 10px;
}

label {
  color: #aaa;
  display: inline-block;
  margin: 25px 0 15px;
  font-size: 0.6em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}

input, select {
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #555;
}

input[type="checkbox"] {
  display: inline-block;
  width: 16px;
  margin: 0 2px 0 15px;
  position: relative;
  top: 2px;
}

input[type="radio"] {
  display: inline-block;
  width: 16px;
  margin: 0 2px 0 15px;
  position: relative;
  top: 2px;
}

.pill {
  display: inline-block;
  margin: 20px 10px 0 0;
  padding: 6px 12px;
  background: #eee;
  border-radius: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: bold;
  color: #777;
  cursor: pointer;
}

button {
  background: #0b6dff;
  border: 0;
  padding: 10px 20px;
  margin-top: 20px;
  color: white;
  border-radius: 20px;
  cursor: pointer;
}

.submit {
  text-align: center;
}

.error {
  color: #ff0062;
  margin-top: 10px;
  font-size: 0.8em;
  font-weight: bold;
}

.red {
  color: red !important;
}
</style>